import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FocusLock,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ButtonIcon from "../../../components/ButtonIcon";
import WhatsappMessageItem from "../../../components/WhatsappMessageItem";
import { appPaths } from "../../../constants/app-paths";
import { colors } from "../../../constants/colors";
import useFileValidation from "../../../hooks/useFileValidation";
import {
  CreateMessageTemplateDto,
  MessageTemplatesService,
} from "../../../services/message-templates.service";
import { ButtonType } from "../../../types/ButtonTypeEnum";
import { GupshupTemplateType } from "../../../types/GupshupTemplateType";
import { TemplateParametersList } from "../../../types/TemplateParametersEnum";
import { ImBold } from "react-icons/im";
import { BsPlus } from "react-icons/bs";
import { FaMagic } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { apiRoutes } from "../../../constants/api-routes";
import { MessageTemplateSuggestionsService } from "../../../services/message-template-suggestions.service";
import { MixpanelService } from "../../../services/mixpanel.service";
import GenerateMessageTemplateModal from "./components/GenerateMessageTemplateModal";
import RegenerateMessageTemplateModal from "./components/RegenerateMessageTemplateModal";
import { CommunicationChannelEnum } from "../../../types/CommunicationChannelEnum";
import CustomEmojiPicker from "../../../components/CustomEmojiPicker";
import { WhatsappTemplateCategoryText, WhatsappTemplateCategory } from "../../../types/WhatsappTemplatecategory";

const emojisMatchRegExp = /^(?:[0-9]|[^\p{Emoji}])*$/u;
const msgThisFieldCannotHaveEmojis =
  "Devido a uma limitação da Meta API, emojis não são permitidos neste campo";

const buttonSchema = yup
  .object({
    type: yup.string().required().oneOf(["QUICK_REPLY", "URL"]),
    text: yup
      .string()
      .required("Texto é obrigatório")
      .max(25, "Limite máx. de 25 caracteres")
      .matches(emojisMatchRegExp, msgThisFieldCannotHaveEmojis),
    url: yup
      .string()
      .url("URL inválida. (Ex: https://www.google.com)")
      .when("type", {
        is: "URL",
        then: yup
          .string()
          .url("URL inválida. (Ex: https://www.google.com)")
          .required("URL é obrigatória"),
      }),
  })
  .required();

const schema = yup.object().shape({
  communicationChannel: yup
    .string()
    .oneOf([CommunicationChannelEnum.SMS, CommunicationChannelEnum.WHATSAPP])
    .required("Canal é obrigatório"),
  elementName: yup
    .string()
    .matches(
      /^[a-z0-9\s]+$/,
      "Nome do template deve conter apenas letras minúsculas, números e espaços em branco"
    )
    .required("Nome do template é obrigatório"),
  whatsappTemplateCategory: yup.string().oneOf(Object.values(WhatsappTemplateCategory)),
  templateType: yup.string().oneOf(Object.values(GupshupTemplateType)), // Substitua 'TEMPLATE_TYPE_1' e 'TEMPLATE_TYPE_2' pelos valores reais do enum GupshupTemplateType
  content: yup.string().when("communicationChannel", {
    is: CommunicationChannelEnum.SMS,
    then: yup
      .string()
      .required("O corpo da mensagem não pode ser deixado em branco")
      .matches(
        /^[a-zA-Z0-9 !@#$%^&*()\[\],;:'"<>/\\?{}|_+-=]*$/,
        "Templates de SMS não devem conter acentos nem emojis"
      )
      .max(96, "Limite máx. de 96 caracteres"),
    otherwise: yup
      .string()
      .required("O corpo da mensagem não pode ser deixado em branco")
      .max(1028, "Limite máx. de 1028 caracteres"),
  }),
  footer: yup
    .string()
    .max(60, "Limite máx. de 60 caracteres")
    .matches(emojisMatchRegExp, msgThisFieldCannotHaveEmojis),
  buttons: yup.array().of(buttonSchema).max(3),
  // exampleMedia: yup.string().optional(),
  messageTemplateSuggestionId: yup.string().optional(),
});

const CreateMessageTemplatePage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      templateType: GupshupTemplateType.TEXT,
      //   elementName: undefined,
      //   content: undefined,
      //   buttons: undefined,
      //   exampleMedia: undefined,
    } as any,
  });
  const {
    fields: buttonTextFields,
    append: appendButtonText,
    remove: removeButtonText,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "buttons", // unique name for your Field Array
  });
  const {
    isOpen: isOpenNewParameter,
    onOpen: onOpenNewParameter,
    onClose: onCloseNewParameter,
  } = useDisclosure();
  const {
    isOpen: isOpenGenerateMessageTemplateModal,
    onOpen: onOpenGenerateMessageTemplateModal,
    onClose: onCloseGenerateMessageTemplateModal,
  } = useDisclosure();
  const {
    isOpen: isOpenRegenerateMessageTemplateModal,
    onOpen: onOpenRegenerateMessageTemplateModal,
    onClose: onCloseRegenerateMessageTemplateModal,
  } = useDisclosure();
  const { data: templateSuggestions } = useQuery(
    apiRoutes.listMessageTemplateSuggestions(),
    async () => {
      const { data } =
        await MessageTemplateSuggestionsService.listMessageTemplateSuggestions();
      return data;
    },
  );
  const watchTemplateType = watch("templateType");
  const watchChannel = watch("communicationChannel");
  const watchContent = watch("content");
  const watchButtons = watch("buttons");
  const watchFooter = watch("footer");
  const [buttonType, setButtonType] = useState<ButtonType | null>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { validateFile, getAcceptedMessageTemplateFileTypes } = useFileValidation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileError, setFileError] = useBoolean(false);
  const contentRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref: contentRegisterRef, ...contentRegister } = register("content");
  const newParameterInputRef = useRef<any>(null);
  const [promptThreadId, setPromptThreadId] = useState<string | null>(null);
  const [selectedSuggestionId, setSelectedSuggestionId] = useState<string>("");

  const queryClient = useQueryClient();
  const createMessageTemplate = useMutation(
    (createMessageTemplateDto: CreateMessageTemplateDto) =>
      MessageTemplatesService.createMessageTemplate(createMessageTemplateDto),
    {
      onSuccess: (res) => {
        MixpanelService.track("create-message-template", {
          messageTemplateSuggestionId: res.data.messageTemplateSuggestionId,
        });
        toast({
          title: "Template criado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        queryClient.invalidateQueries(
          apiRoutes.listMessageTemplateSuggestions()
        );
        navigate(appPaths.messageTemplates.index());
      },
    }
  );

  useEffect(() => {
    setSelectedFile(null);
  }, [watchTemplateType]);

  function handleClickParameter(parameter: any) {
    const textarea = contentRef.current! as any;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      parameter +
      currentValue.substring(endPosition);

    setValue("content", newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + parameter.length + 1,
      startPosition + parameter.length + 1
    );
  }

  async function handleClickBold() {
    const textarea = contentRef.current! as any;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      "*" +
      currentValue.substring(startPosition, endPosition) +
      "*" +
      currentValue.substring(endPosition);

    setValue("content", newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + 1,
      startPosition + 1 + (endPosition - startPosition)
    );
  }

  async function onSubmit(data: Omit<CreateMessageTemplateDto, "file">) {
    if (data.templateType !== GupshupTemplateType.TEXT) {
      if (!selectedFile) {
        setFileError.on();
        return;
      }
    }

    await createMessageTemplate.mutateAsync({
      ...data,
      file: selectedFile || undefined,
    });
  }

  async function handleChangeFile(event: any) {
    const file = event.target.files && event.target.files[0];
    const isValidFile = validateFile(file);
    if (!isValidFile) {
      event.target.value = null;
      return;
    }
    setSelectedFile(file);
  }

  function handleAddNewParameter() {
    let newParameter = newParameterInputRef.current?.value;
    newParameter = `[${newParameter.replace(/(\[|\])/g, "")}]`;

    if (!newParameter) {
      return;
    }
    if (
      newParameter.normalize("NFD").replace(/[\u0300-\u036f]/g, "") !==
      newParameter
    ) {
      toast({
        title: "O parâmetro não pode conter acentos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (TemplateParametersList.includes(newParameter)) {
      toast({
        title: "Esse nome de parâmetro já existe",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    handleClickParameter(newParameter);
    handleCloseNewParameter();
  }

  function handleCloseNewParameter() {
    newParameterInputRef.current!.value = null;
    onCloseNewParameter();
  }

  function handleChangeTemplateSuggestion(templateSuggestionId: string) {
    const selectedTemplateSuggestion = templateSuggestions?.find((template) => {
      return template.id === templateSuggestionId;
    });

    if (selectedTemplateSuggestion) {
      MixpanelService.track("click-template-suggestion", {
        messageTemplateSuggestionName: selectedTemplateSuggestion.name,
      });
      setValue("messageTemplateSuggestionId", templateSuggestionId);
      setValue("content", selectedTemplateSuggestion.templateText);
      setSelectedSuggestionId(templateSuggestionId);
    } else {
      setValue("messageTemplateSuggestionId", "");
      setValue("content", "");
      setSelectedSuggestionId("");
    }
  }

  function handleGenerateTemplate(content: string, promptThreadId: string) {
    setValue("content", content);
    setPromptThreadId(promptThreadId);
  }

  function handleRegenerateTemplate(content: string) {
    setValue("content", content);
  }

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const content = event.target.value;

    if (!content.trim()) {
      setValue("messageTemplateSuggestionId", "");
      setSelectedSuggestionId("");
    }
  };

  return (
    <Grid
      height="auto"
      templateColumns="2fr 1fr"
      paddingTop="50px"
      paddingBottom="50px"
      paddingX="100px"
      alignItems="start"
      gap={4}
    >
      <GridItem>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>Canal de envio</FormLabel>
                  <Controller
                    name="communicationChannel"
                    control={control}
                    defaultValue={CommunicationChannelEnum.WHATSAPP}
                    render={({ field }) => (
                      <Select onChange={field.onChange} value={field.value}>
                        <option value={CommunicationChannelEnum.WHATSAPP}>
                          WhatsApp
                        </option>
                        <option value={CommunicationChannelEnum.SMS}>
                          SMS
                        </option>
                      </Select>
                    )}
                  />
                  <Text color={colors.danger} fontSize="xs">
                    {errors.communicationChannel?.message}
                  </Text>
                </FormControl>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>Nome do template</FormLabel>
                  <Input
                    placeholder="Escreva o nome do template"
                    {...register("elementName")}
                    isInvalid={!!errors.elementName}
                  />
                  <Text color={colors.danger} fontSize="xs">
                    {errors.elementName?.message}
                  </Text>
                </FormControl>
              </CardBody>
            </Card>
            {watchChannel === CommunicationChannelEnum.WHATSAPP && (
              <>
                <Card>
                  <CardBody>
                    <FormControl>
                      <FormLabel>Categoria</FormLabel>
                      <Select {...register("whatsappTemplateCategory")}>
                        {[
                          {
                            id: WhatsappTemplateCategory.MARKETING,
                            name: WhatsappTemplateCategoryText.MARKETING,
                          },
                          {
                            id: WhatsappTemplateCategory.UTILITY,
                            name: WhatsappTemplateCategoryText.UTILITY,
                          },
                        ].map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <FormControl>
                      <FormLabel>Cabeçalho</FormLabel>
                      <Select {...register("templateType")}>
                        {[
                          {
                            id: GupshupTemplateType.TEXT,
                            name: "Nenhum",
                          },
                          {
                            id: GupshupTemplateType.DOCUMENT,
                            name: "Arquivo PDF",
                          },
                          {
                            id: GupshupTemplateType.IMAGE,
                            name: "Imagem",
                          },
                          {
                            id: GupshupTemplateType.VIDEO,
                            name: "Vídeo",
                          },
                        ].map((templateType) => (
                          <option key={templateType.id} value={templateType.id}>
                            {templateType.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {watchTemplateType !== GupshupTemplateType.TEXT && (
                      <FormControl>
                        <FormLabel>Arquivo</FormLabel>
                        <input
                          type="file"
                          onChange={handleChangeFile}
                          accept={getAcceptedMessageTemplateFileTypes(watchTemplateType)}
                        />
                        {fileError && (
                          <Text color={colors.danger} fontSize="xs">
                            {"Arquivo é obrigatório"}
                          </Text>
                        )}
                      </FormControl>
                    )}
                  </CardBody>
                </Card>
              </>
            )}
            {templateSuggestions && templateSuggestions.length > 0 && (
              <Card>
                <CardBody>
                  <FormControl>
                    <FormLabel>Sugestão de mensagem</FormLabel>
                    <Select
                      placeholder="Selecione uma mensagem"
                      value={selectedSuggestionId}
                      onChange={(e) => {
                        handleChangeTemplateSuggestion(e.target.value);
                      }}
                    >
                      {templateSuggestions.map((template) => (
                        <option
                          key={template.id}
                          value={template.id}
                          title={template.templateText}
                          >
                            {template.name}
                          </option>
                      ))}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            )}
            <Card>
              <CardBody>
                <FormControl>
                  <Flex alignItems={"center"} justifyContent="space-between">
                    <FormLabel>Corpo</FormLabel>
                  </Flex>
                  <Box>
                    <Textarea
                      isDisabled={!!promptThreadId}
                      placeholder="Escreva o texto da mensagem"
                      value={watchContent}
                      {...contentRegister}
                      ref={(e) => {
                        contentRegisterRef(e);
                        contentRef.current = e;
                      }}
                      onChange={(e) => {
                        contentRegister.onChange(e);
                        handleContentChange(e);
                      }}
                      isInvalid={!!errors.content}
                    />
                    {watchChannel === CommunicationChannelEnum.WHATSAPP && (
                      <Flex
                        justifyContent={"space-between"}
                        width="100%"
                        mt={1}
                        position={"relative"}
                      >
                        {!!promptThreadId ? (
                          <Flex gap={3}>
                            <Button
                              onClick={() => setPromptThreadId(null)}
                              leftIcon={<FaEdit />}
                            >
                              Editar
                            </Button>
                            <Button
                              onClick={onOpenRegenerateMessageTemplateModal}
                              leftIcon={<FaArrowRotateLeft />}
                            >
                              Regenerar
                            </Button>
                          </Flex>
                        ) : (
                          <Button
                            onClick={onOpenGenerateMessageTemplateModal}
                            leftIcon={<FaMagic />}
                          >
                            Gerador da Revi
                          </Button>
                        )}
                        <Box>
                          <ButtonIcon
                            icon={<ImBold />}
                            onClick={handleClickBold}
                          />
                          <CustomEmojiPicker onEmojiSelection={handleClickParameter} />
                        </Box>
                      </Flex>
                    )}
                  </Box>
                  <Text color={colors.danger} fontSize="xs">
                    {errors.content?.message}
                  </Text>
                </FormControl>
                <Box mt={3}>
                  <Text>Adicionar parâmetro</Text>
                  <Flex
                    gap={2}
                    alignItems="center"
                    flexWrap={"wrap"}
                    maxW="100%"
                  >
                    {TemplateParametersList.map((parameter) => (
                      <Button
                        key={parameter}
                        fontSize="xs"
                        color="gray.500"
                        mt={2}
                        mb={2}
                        variant="outline"
                        onClick={() => handleClickParameter(parameter)}
                      >
                        {parameter}
                      </Button>
                    ))}
                    <Popover
                      isOpen={isOpenNewParameter}
                      onOpen={onOpenNewParameter}
                      onClose={handleCloseNewParameter}
                      initialFocusRef={newParameterInputRef}
                      placement="right"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Novo parâmetro"
                          icon={<BsPlus />}
                        />
                      </PopoverTrigger>
                      <PopoverContent p={5}>
                        <FocusLock persistentFocus={false}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <Stack spacing={4}>
                            <FormControl>
                              <FormLabel>Nome do parâmetro</FormLabel>
                              <Input
                                ref={newParameterInputRef}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleAddNewParameter();
                                  }
                                }}
                                placeholder="Ex: codigo do cupom, vencimento"
                              />
                            </FormControl>
                            <ButtonGroup
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="outline"
                                onClick={handleCloseNewParameter}
                              >
                                Cancelar
                              </Button>
                              <Button
                                bgColor={colors.lightGrey}
                                onClick={handleAddNewParameter}
                              >
                                Adicionar
                              </Button>
                            </ButtonGroup>
                          </Stack>
                        </FocusLock>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                </Box>
              </CardBody>
            </Card>
            {watchChannel === CommunicationChannelEnum.WHATSAPP && (
              <Card>
                <CardBody>
                  <FormControl>
                    <FormLabel>Rodapé</FormLabel>
                    <Input
                      placeholder="Ex: Para sair envie STOP"
                      {...register("footer")}
                      isInvalid={!!errors.footer}
                    />
                    <Text color={colors.danger} fontSize="xs">
                      {errors.footer?.message}
                    </Text>
                  </FormControl>
                </CardBody>
              </Card>
            )}
            <Card>
              <CardBody display={"flex"} flexDir="column" gap={4}>
                <FormControl>
                  <FormLabel>Botões & Links</FormLabel>
                  <Select
                    placeholder="Nenhum"
                    onChange={(event) => {
                      const selectedButtonType = event.target
                        .value as ButtonType;
                      setButtonType(selectedButtonType);
                      if (!!selectedButtonType) {
                        removeButtonText();
                        appendButtonText({
                          type: selectedButtonType,
                          text: "",
                        });
                      }
                    }}
                  >
                    {[
                      {
                        id: ButtonType.QUICK_REPLY,
                        name: "Resposta rápida",
                      },
                      {
                        id: ButtonType.URL,
                        name: "Abrir URL",
                      },
                    ]
                      .filter(
                        (el) =>
                          !(
                            watchChannel === CommunicationChannelEnum.SMS &&
                            el.id === ButtonType.QUICK_REPLY
                          )
                      )
                      .map((templateType) => (
                        <option key={templateType.id} value={templateType.id}>
                          {templateType.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                {buttonType === ButtonType.QUICK_REPLY ? (
                  buttonTextFields.map((field, index) => (
                    <Flex display={"flex"} alignItems={"center"}>
                      <FormControl
                        w="500px"
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <FormLabel width={"80px"}>Botão {index + 1}</FormLabel>
                        <Input
                          key={field.id}
                          placeholder="Título do botão"
                          {...register(`buttons.${index}.text`)}
                          isInvalid={!!errors?.buttons?.[index]?.text}
                        />
                        <Text color={colors.danger} fontSize="xs">
                          {errors?.buttons?.[index]?.text?.message}
                        </Text>
                      </FormControl>
                      {buttonTextFields.length > 1 && (
                        <ButtonIcon
                          icon={
                            <FaTrash fontSize="20px" color={colors.danger} />
                          }
                          onClick={() => removeButtonText(index)}
                        />
                      )}
                    </Flex>
                  ))
                ) : buttonType === ButtonType.URL ? (
                  <Flex gap={2}>
                    <FormControl w="250px">
                      <FormLabel>Botão</FormLabel>
                      <Input
                        placeholder="Título do botão"
                        {...register(`buttons.0.text`)}
                        isInvalid={!!errors?.buttons?.[0]?.text}
                      />
                      <Text color={colors.danger} fontSize="xs">
                        {errors?.buttons?.[0]?.text?.message}
                      </Text>
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel>URL</FormLabel>
                      <Input
                        placeholder="URL do botão"
                        {...register(`buttons.0.url`)}
                        isInvalid={!!errors?.buttons?.[0]?.url}
                      />
                      <Text color={colors.danger} fontSize="xs">
                        {errors?.buttons?.[0]?.url?.message}
                      </Text>
                    </FormControl>
                  </Flex>
                ) : null}
                {buttonType === ButtonType.QUICK_REPLY &&
                  buttonTextFields.length <= 2 && (
                    <Button
                      onClick={() => {
                        appendButtonText({
                          type: buttonType,
                          text: "",
                        });
                      }}
                      width={"fit-content"}
                    >
                      + Adicionar botão
                    </Button>
                  )}
              </CardBody>
            </Card>
            <Flex justify={"flex-end"}>
              <Button
                width="30%"
                isLoading={createMessageTemplate.isLoading}
                color={colors.white}
                bgColor={colors.primary}
                type="submit"
              >
                Criar
              </Button>
            </Flex>
          </Stack>
        </form>
      </GridItem>
      <GridItem position={"sticky"} top={0} alignSelf="start">
        <Box
          height="100vh"
          width="361px"
          backgroundImage={"/template-preview-bg.png"}
          padding={3}
        >
          <WhatsappMessageItem
            message={watchContent}
            file={selectedFile}
            buttons={watchButtons}
            footer={watchFooter}
          />
        </Box>
      </GridItem>
      <GenerateMessageTemplateModal
        isOpen={isOpenGenerateMessageTemplateModal}
        onClose={onCloseGenerateMessageTemplateModal}
        onGenerateMessageTemplate={handleGenerateTemplate}
      />
      <RegenerateMessageTemplateModal
        isOpen={isOpenRegenerateMessageTemplateModal}
        onClose={onCloseRegenerateMessageTemplateModal}
        onRegenerateMessageTemplate={handleRegenerateTemplate}
        promptThreadId={promptThreadId}
      />
    </Grid>
  );
};

export default CreateMessageTemplatePage;
